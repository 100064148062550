(function($) {
	// Add smooth scrolling to all links
	$('.scroll-down-btn').on('click', function(event) {

		event.preventDefault();

		// Using jQuery's animate() method to add smooth page scroll
		// The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
		$('html, body').animate({
			scrollTop: $('#scroll-to-here').offset().top
		}, 800);

	});

	$(window).scroll(function(){
		var scroll = $(window).scrollTop();

		if (scroll >= 500) {
			$('.scroll-down-btn').css('opacity','0');
		} else {
			$('.scroll-down-btn').css('opacity','1');
		}
	});

})(jQuery);